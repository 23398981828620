@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;
.c-header {
    width: 100%;
    background-color: #fff;
    position: fixed;
    z-index: 10;
    @include r.sp {}
    &__inner {
        height: 92px;
        margin: 0 1% 0 2%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include r.sp {
            height: 60px;
        }
    }
    &__logo {
        display: block;
        width: 150px;
        @include r.pc-1440 {
            width: 120px;
        }
        @include r.pc-1280 {
            width: 110px;
        }
        @include r.sp {
            display: inline-block;
            width: 98px;
            margin-left: 0;
        }
    }
    &__right {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @include r.pc-1024 {
            display: none;
        }
    }
    &__menu_list {
        margin-right: 50px;
        display: flex;
        @include r.pc-1440 {
            margin-right: 20px;
        }
        @include r.pc-1280 {
            margin-right: 20px;
        }
        @include r.pc-1024 {
            margin-right: 10px;
        }
        li {
            font-size: 1.6rem;
            letter-spacing: 0.5px;
            @include r.pc-1440 {
                font-size: 1.5rem;
            }
            @include r.pc-1280 {
                font-size: 1.3rem;
            }
            a {
                display: block;
                color: v.$nav-link-gray-color;
                transition: v.$anime-transition;
                &::after {
                    content: '';
                    display: block;
                    width: 100%;
                    background-color: v.$text-brown-color;
                    transition: v.$anime-transition;
                    height: 1px;
                    opacity: 0;
                }
                &:hover {
                    transform: translateY(5px);
                    color: v.$text-brown-color;
                    &::after {
                        opacity: 1;
                    }
                }
            }
        }
        li:not(:last-child) {
            margin-right: 25px;
            @include r.pc-1440 {
                margin-right: 20px;
            }
            @include r.pc-1280 {
                margin-right: 10px;
            }
            @include r.pc-1024 {
                margin-right: 10px;
            }
        }
    }
    &__sns_list {
        margin-right: 30px;
        display: flex;
        @include r.pc-1440 {
            margin-right: 20px;
        }
        @include r.pc-1280 {
            margin-right: 10px;
        }
        li {
            font-size: 1.4rem;
            font-family: v.$font-lexend-exa;
            letter-spacing: -0.5px;
            font-weight: 300; //light
            @include r.pc-1440 {
                font-size: 1.3rem;
            }
            @include r.pc-1280 {
                font-size: 1.2rem;
            }
            a {
                display: block;
                color: v.$nav-link-gray-color;
                transition: v.$anime-transition;
                &::after {
                    content: '';
                    display: block;
                    width: 100%;
                    background-color: v.$text-brown-color;
                    transition: v.$anime-transition;
                    height: 1px;
                    opacity: 0;
                }
                &:hover {
                    transform: translateY(5px);
                    color: v.$text-brown-color;
                    &::after {
                        opacity: 1;
                    }
                }
            }
        }
        li:not(:last-child) {
            margin-right: 15px;
            @include r.pc-1280 {
                margin-right: 10px;
            }
        }
    }
    &__btn {
        font-weight: 700; //bold
        width: 236px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        color: v.$main-orange-color;
        border: 2px solid v.$main-orange-color;
        font-size: 1.5rem;
        text-align: center;
        transition: v.$anime-transition;
        span {
            @include r.pc-1024 {
                display: none;
            }
        }
        @include r.pc-1440 {}
        @include r.pc-1280 {
            width: 180px;
            height: 50px;
            font-size: 1.3rem;
        }
        @include r.pc-1024 {
            width: 60px;
            height: 60px;
            background-color: v.$main-orange-color;
            border: none;
            position: relative;
            // text-indent: -9999px;
            &::before {
                width: 14px;
                height: 10px;
                content: '';
                position: absolute;
                top: 50%;
                left: 46%;
                transform: translate( -46%, -50%);
                display: inline-block;
                background-repeat: no-repeat;
                background-size: 100%;
                background-image: url(p.$path + 'images/common/icon_cart_wh.svg');
                -webkit-transform: translate( -46%, -50%);
                -moz-transform: translate( -46%, -50%);
                -ms-transform: translate( -46%, -50%);
                -o-transform: translate( -46%, -50%);
            }
        }
        &:hover {
            color: #fff;
            background-color: v.$main-orange-color;
        }
    }
    &__open_btn {
        display: none;
        position: relative;
        z-index: 7777;
        width: 60px;
        height: 60px;
        margin-left: auto;
        background-color: v.$text-brown-color;
        transition: v.$anime-transition;
        @include r.pc-1024 {
            display: block;
        }
        // 三本線の共通スタイル
        span {
            position: absolute;
            left: 50%;
            width: 16px;
            height: 1px;
            background-color: #fff;
            transform: translateX(-50%);
            transition: v.$anime-transition;
        }
        // 上の線
        span:nth-child(1) {
            top: calc(50% - 4px); // 上と中央の間隔を調整
        }
        // 中央の線
        span:nth-child(2) {
            top: 50%;
        }
        // 下の線
        span:nth-child(3) {
            top: calc(50% + 4px); // 中央と下の間隔を調整
        }
        // ✕のデザイン
        &.active {
            background-color: #000000;
            span:nth-child(1) {
                transform: translateX(-50%) rotate(45deg) translateY(-50%);
                top: 50%;
            }
            span:nth-child(2) {
                opacity: 0;
            }
            span:nth-child(3) {
                transform: translateX(-50%) rotate(-45deg) translateY(-50%);
                top: 50%;
            }
        }
    }
}

.c-sp-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    padding-top: 100px;
    padding-bottom: 60px;
    color: #fff;
    background-color: v.$text-brown-color;
    z-index: 9;
    overflow-y: auto;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease;
    @include r.sp {
        padding-top: 70px;
    }
    &.active {
        opacity: 1;
        pointer-events: auto;
    }
    &__menu_list {
        width: 80%;
        margin: 0 auto 50px;
        >li {
            font-size: 1.5rem;
            font-weight: 500;
            &::before {
                display: none;
            }
            >a {
                display: block;
                padding: 15px 0;
                display: flex;
                align-items: center;
                transition: v.$anime-transition;
                position: relative;
                &::before {
                    content: '';
                    width: 2px;
                    height: 2px;
                    display: inline-block;
                    background-color: v.$main-orange-color;
                    border-radius: 50%;
                    margin-right: 10px;
                    transition: v.$anime-transition;
                }
                .c-arrow {
                    margin-left: 15px;
                    width: 20px;
                }
            }
        }
    }
    &__menu_child_list {
        margin-left: 30px;
        li {
            &::before {
                display: none;
            }
            font-size: 1.3rem;
            font-weight: 400;
            a {
                padding: 12px 0;
                padding-left: 20px !important;
            }
        }
    }
    &__icon {
        display: block;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: - 10px;
            transform: translate(0, -50%);
            display: inline-block;
            background-repeat: no-repeat;
            background-size: 100%;
            z-index: -1;
        }
        &--1 {
            &::before {
                width: 10px;
                height: 10px;
                background-image: url(p.$path + 'images/common/icon_pudding_or.svg');
            }
        }
        &--2 {
            &::before {
                width: 13px;
                height: 10px;
                background-image: url(p.$path + 'images/common/icon_financie_or.svg');
            }
        }
        &--3 {
            &::before {
                width: 12px;
                height: 12px;
                background-image: url(p.$path + 'images/common/icon_cake_or.svg');
            }
        }
        &--4 {
            &::before {
                width: 11px;
                height: 11px;
                background-image: url(p.$path + 'images/common/icon_bakedsweets_1_or.svg');
            }
        }
        &--5 {
            &::before {
                width: 11px;
                height: 11px;
                background-image: url(p.$path + 'images/common/icon_gift_or.svg');
            }
        }
    }
    &__sns_list {
        width: 80%;
        margin: 0 auto;
        display: flex;
        li {
            width: 50%;
            font-size: 1.3rem;
            font-family: v.$font-lexend-exa;
            line-height: 18px;
            font-weight: 300; //light
            a {
                display: block;
                color: #fff;
            }
        }
    }
}
