@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;
//--footer--------------------------
.c-footer {
    background-color: #fff;
    &__inner {
        margin-top: 125px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        @include r.pc-1024 {
            margin-top: 50px;
            flex-direction: column;
        }
    }
    &__logo {
        width: 244px;
        margin-top: 20px;
        margin-bottom: 36px;
        @include r.sp {
            display: inline-block;
            width: 162px;
            margin-top: 0;
            margin-bottom: 27px;
        }
    }
    &__left {
        width: 45%;
        @include r.pc-1440 {
            width: 40%;
        }
        @include r.pc-1024 {
            width: 100%;
            padding-top: 40px;
            border-top: 1px solid #d5d3d3;
            order: 2;
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-bottom: 30px;
        }
    }
    &__right {
        width: 55%;
        display: flex;
        justify-content: space-between;
        @include r.pc-1440 {
            width: 60%;
        }
        @include r.pc-1024 {
            margin-bottom: 60px;
            flex-wrap: wrap;
            width: 100%;
            order: 1;
        }
    }
    &__copy {
        width: 100%;
        margin-top: 115px;
        margin-bottom: 50px;
        font-size: 1.4rem;
        text-align: center;
        color: #d5d3d3;
        @include r.pc-1024 {
            margin-bottom: 40px;
            margin-top: 0;
            order: 3;
            font-size: 1rem;
        }
    }
    &__name {
        margin-bottom: 15px;
        font-size: 2.4rem;
        letter-spacing: 1.2px;
        line-height: 35px;
        font-weight: 700;
        @include r.sp {
            margin-bottom: 10px;
            font-size: 1.6rem;
            letter-spacing: 0.8px;
            line-height: 23px;
        }
    }
    &__adress {
        margin-bottom: 70px;
        font-size: 1.6rem;
        letter-spacing: 0.8px;
        line-height: 32px;
        @include r.sp {
            margin-bottom: 30px;
            font-size: 1.2rem;
            letter-spacing: 0.6px;
            line-height: 24px;
            text-align: center;
        }
    }
    &__sns_list {
        margin-right: 90px;
        display: flex;
        text-align: center;
        @include r.sp {
            justify-content: center;
            width: 100%;
            margin-right: 0;
        }
        li {
            font-size: 1.4rem;
            font-family: v.$font-lexend-exa;
            letter-spacing: -0.7px;
            line-height: 18px;
            font-weight: 300; //light
            @include r.sp {
                font-size: 0.9rem;
                letter-spacing: -0.45px;
            }
            &:not(:last-child) {
                margin-right: 20px;
                @include r.sp {
                    margin-right: 40px;
                }
            }
            a {
                display: block;
                color: v.$nav-link-gray-color;
                transition: all 0.6s;
                &::after {
                    content: '';
                    display: block;
                    width: 100%;
                    background-color: v.$text-brown-color;
                    transition: all 0.6s;
                    height: 1px;
                    opacity: 0;
                }
                @include r.hover {
                    transform: translateY(5px);
                    color: v.$text-brown-color;
                    &::after {
                        opacity: 1;
                    }
                }
            }
        }
    }
    &__menu_list {
        width: 100%;
        &:first-child {
            margin-right: 80px;
            @include r.sp {
                margin-right: 0;
            }
        }
        >li {
            font-weight: 500;
            @include r.sp {
                line-height: 17.5px;
            }
        }
        li:not(:last-child) {
            margin-bottom: 30px;
            @include r.sp {
                margin-bottom: 20px;
            }
        }
    }
    &__menu_child_list {
        margin-top: 27px;
        @include r.sp {
            margin-top: 20px;
            margin-bottom: 20px;
        }
        li {
            font-weight: 400;
            @include r.sp {
                margin-left: 5px;
            }
            &:not(:last-child) {
                margin-bottom: 20px !important;
                @include r.sp {
                    margin-bottom: 0;
                    font-size: 1.1rem;
                    line-height: 10px;
                }
            }
        }
    }
}
