@use 'sass:map';
$breakpoints: ( 'smless': ( max-width: 640px, ), 'smover': ( min-width: 641px, ), 'mdless': ( max-width: 767px, ), 'mdover': ( min-width: 768px, ), 'lgless': ( max-width: 1024px, ), 'lgover': ( min-width: 1025px, ), 'xlless': ( max-width: 1280px, ), 'xlover': ( min-width: 1281px, ), 'xxlless': ( max-width: 1440px, ), 'xxlover': ( min-width: 1441px, ), 'maxless': ( max-width: 1920px, ), 'maxover': ( min-width: 1921px, ), ) !default;
@mixin mq($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media screen and #{inspect(map-get($breakpoints, $breakpoint))} {
            @content;
        }
    }
    @else {
        @error "指定されたブレイクポイントはありません -> #{map-keys($breakpoints)}";
    }
}

@mixin mincyo() {
    font-family: '游明朝', YuMincho, 'ヒラギノ明朝 ProN W3', 'Hiragino Mincho ProN', 'HG明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
}

// @mixin lexend-exa() {
//   font-family: 'Lexend Exa', sans-serif;
// }
@mixin hover {
    @include mq(lgover) {
        &:hover {
            @content;
        }
    }
}

@mixin desktop-pc {
    @include mq(maxover) {
        @content;
    }
}

@mixin pc {
    @include mq(mdover) {
        @content;
    }
}

//max-width: 1440px
@mixin pc-1440 {
    @include mq(xxlless) {
        @content;
    }
}

//max-width: 1280px
@mixin pc-1280 {
    @include mq(xlless) {
        @content;
    }
}

// max-width: 1024px
@mixin pc-1024 {
    @include mq(lgless) {
        @content;
    }
}

// @mixin pad {
//     @include max-w(1024px) {
//         @content;
//     }
// }
@mixin sp {
    @include mq(mdless) {
        @content;
    }
}

@mixin min-w($breakpoint) {
    @media screen and (min-width: #{$breakpoint}) {
        @content;
    }
}

@mixin max-w($breakpoint) {
    @media screen and (max-width: #{$breakpoint}) {
        @content;
    }
}

@mixin clear {
    *zoom: 1;
    &:after {
        display: block;
        content: '';
        clear: both;
    }
}

@mixin en {
    .en & {
        @content;
    }
}
