@use "../settings/variable"as v;
@use "../settings/mixin"as r;
@use "../settings/_path"as p;

/*------------------------------------------------------------
img
------------------------------------------------------------*/

// aタグ内の画像はhoverされた時の効果
.c-image-link {
    position: relative;
    display: inline-block;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.3);
        opacity: 0;
        transition: opacity 0.6s ease;
    }
    @include r.hover {
        &::before {
            opacity: 1;
        }
    }
}
