@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
other
------------------------------------------------------------*/

.c-breadcrumb {
    margin-bottom: 35px;
    font-size: 1.2rem;
    letter-spacing: 0.84px;
    line-height: 32px;
    @include r.sp {
        display: none;
    }
}

.c-breadcrumb__item {
    display: inline;
    color: v.$main-orange-color;
}

.c-breadcrumb__item:not(:last-child) a:after,
.c-breadcrumb__item:not(:last-child) span:after {
    margin: 0 8px;
    display: inline-block;
    content: '>';
    color: v.$text-brown-color;
    text-decoration: none;
}

.c-breadcrumb a,
.c-breadcrumb span {
    display: inline;
}

.c-breadcrumb a {
    color: v.$text-brown-color;
}

@media screen and (min-width: 768px) {
    .c-breadcrumb a:hover {
        opacity: 1;
        text-decoration: underline;
    }
}


/*------------------------------------------------------------
product hero
------------------------------------------------------------*/

.p-page-hero {
    margin-bottom: 20px;
    height: 279px;
    display: flex;
    background-image: url(p.$path + 'images/product/product_hero_pc.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    @include r.sp {
        align-items: center;
        margin-bottom: 40px;
        height: 139px;
        background-image: url(p.$path + 'images/product/product_hero_sp.jpg');
    }
}

.c-page-hero {
    margin-bottom: 27px;
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
    @include r.pc-1440 {
        padding-left: 2%;
        padding-right: 2%;
    }
    &__heading {
        margin-top: 115px;
        margin-bottom: 10px;
        font-size: 3.6rem;
        letter-spacing: 2.52px;
        font-weight: 500;
        line-height: 53px;
        color: #fff;
        @include r.sp {
            margin-left: 30px;
            margin-top: 0px;
            margin-bottom: 5px;
            font-size: 1.6rem;
            line-height: 25px;
            letter-spacing: 1.12px;
        }
    }
    &__sub_heading {
        display: block;
        font-size: 1.4rem;
        letter-spacing: -0.7px;
        font-weight: 300;
        font-family: v.$font-lexend-exa;
        @include r.sp {
            letter-spacing: -0.4px;
            font-size: 0.8rem;
        }
    }
}

//pudding
.p-pudding {
    .p-page-hero {
        background-image: url(p.$path + 'images/product/pudding/pudding_hero_pc.jpg');
        @include r.sp {
            background-image: url(p.$path + 'images/product/pudding/pudding_hero_sp.jpg');
        }
    }
}

//pudding
.p-financier {
    .p-page-hero {
        background-image: url(p.$path + 'images/product/financier/financier_hero_pc.jpg');
        @include r.sp {
            background-image: url(p.$path + 'images/product/financier/financier_hero_sp.jpg');
        }
    }
}

//bakedsweets
.p-bakedsweets {
    .p-page-hero {
        background-image: url(p.$path + 'images/product/bakedsweets/bakedsweets_hero_pc.jpg');
        @include r.sp {
            background-image: url(p.$path + 'images/product/bakedsweets/bakedsweets_hero_sp.jpg');
        }
    }
}

//cake
.p-cake {
    .p-page-hero {
        background-image: url(p.$path + 'images/product/cake/cake_hero_pc.jpg');
        @include r.sp {
            background-image: url(p.$path + 'images/product/cake/cake_hero_sp.jpg');
        }
    }
}

//gift
.p-gift {
    .p-page-hero {
        background-image: url(p.$path + 'images/product/gift/gift_hero_pc.jpg');
        @include r.sp {
            background-image: url(p.$path + 'images/product/gift/gift_hero_sp.jpg');
        }
    }
}

//concept
.p-concept {
    .p-page-hero {
        background-image: url(p.$path + 'images/concept/concept_hero_pc.jpg');
        @include r.sp {
            background-image: url(p.$path + 'images/concept/concept_hero_sp.jpg');
        }
    }
}

//corporate-gift
.p-corporate-gift {
    .p-page-hero {
        background-image: url(p.$path + 'images/corporate-gift/corporate-gift_hero_pc.jpg');
        @include r.sp {
            background-image: url(p.$path + 'images/corporate-gift/corporate-gift_hero_sp.jpg');
        }
    }
}

//contact
.p-contact {
    .p-page-hero {
        background-image: url(p.$path + 'images/contact/contact_hero_pc.jpg');
        @include r.sp {
            background-image: url(p.$path + 'images/contact/contact_hero_sp.jpg');
        }
    }
}

//information
.p-information {
    .p-page-hero {
        background-image: url(p.$path + 'images/information/information_hero_pc.jpg');
        @include r.sp {
            background-image: url(p.$path + 'images/information/information_hero_sp.jpg');
        }
    }
}

//shop
.p-shop {
    .p-page-hero {
        background-image: url(p.$path + 'images/shop/shop_hero_pc.jpg');
        @include r.sp {
            background-image: url(p.$path + 'images/shop/shop_hero_sp.jpg');
        }
    }
}

//privacy
.p-privacy {
    .p-page-hero {
        background-image: url(p.$path + 'images/privacy/privacy_hero_pc.jpg');
        @include r.sp {
            background-image: url(p.$path + 'images/privacy/privacy_hero_sp.jpg');
        }
    }
}

//--------------------------------
//financieページのmargin
.p-financier .c-product-intro,
.p-bakedsweets .c-product-intro,
.p-pudding .c-product-intro,
.p-concept .c-product-intro {
    margin-top: 115px;
    @include r.sp {
        margin-top: 0;
    }
}

.p-cake .c-product-intro {
    margin-top: 65px;
    margin-bottom: 55px;
    @include r.sp {
        margin-top: 0;
    }
}

.p-gift .c-product-intro {
    margin-top: 65px;
    @include r.sp {
        margin-top: 0;
    }
}

.p-bakedsweets .c-product-intro {
    margin-bottom: 55px;
}

.p-corporate-gift .c-product-intro {
    margin-top: 70px;
    margin-bottom: 40px;
    @include r.sp {
        margin-top: 40px;
    }
}

//--------------------------------
.c-product-intro {
    margin-bottom: 90px;
    @include r.sp {
        margin-bottom: 40px;
    }
    &__title {
        margin-bottom: 15px;
        font-size: 2.2rem;
        font-weight: 700;
        letter-spacing: 1.54px;
        line-height: 50px;
        @include r.sp {
            margin-bottom: 10px;
            font-size: 1.5rem;
            line-height: 25px;
            letter-spacing: 0.7px;
        }
    }
    &__txt {
        font-size: 1.8rem;
        letter-spacing: 1.26px;
        line-height: 40px;
        font-weight: 500;
        @include r.sp {
            //   margin-bottom: 15px;
            font-size: 1.2rem;
            line-height: 25px;
            letter-spacing: 0.84px;
        }
        &--atten {
            margin-top: 16px;
            font-size: 1.4rem;
            letter-spacing: 0.98px;
            line-height: 32px;
            @include r.sp {
                margin-top: 8px;
                font-size: 0.9rem;
                letter-spacing: 0.63px;
                line-height: 20px;
            }
        }
    }
    &--form {
        margin-bottom: 50px;
        text-align: center;
        @include r.sp {
            margin-bottom: 20px;
        }
    }
    &--form-thanks {
        margin-bottom: 90px;
        text-align: center;
        @include r.sp {
            margin-bottom: 45px;
        }
    }
}
