@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
list
------------------------------------------------------------*/

//--list---
//--product page only
//item category----
.c-product-list-01 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .c-btn-end {
        @include r.sp {
            justify-content: left;
        }
    }
}

.c-product-list-01-item {
    display: block;
    width: 45%;
    @include r.sp {
        width: 100%;
    }
    &:not(:last-child) {
        margin-bottom: 80px;
        @include r.sp {
            margin-bottom: 35px;
        }
    }
    @include r.hover {
        .c-more-btn .c-arrow {
            transform: translateX(20px);
        }
        .c-more-btn::before {
            transform: scale(1);
        }
        @include r.hover {
            .c-image-link::before {
                opacity: 1;
            }
        }
    }
    &__image {
        margin-bottom: 23px;
        @include r.sp {
            margin-bottom: 0;
        }
    }
    &__heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 3rem;
        line-height: 50px;
        margin-bottom: 30px;
        font-weight: 500;
        @include r.pc-1024 {
            flex-direction: column;
            align-items: flex-start;
        }
        @include r.sp {
            flex-direction: row;
            font-size: 1.6rem;
            margin-bottom: 0;
        }
    }
    &__sub_heading {
        font-size: 1.4rem;
        letter-spacing: -1.5px;
        font-weight: 300;
        color: v.$main-orange-color;
        font-family: v.$font-lexend-exa;
        @include r.pc-1024 {
            display: block;
        }
        @include r.sp {
            font-size: 0.7rem;
        }
    }
    &__txt {
        font-size: 1.6rem;
        margin-bottom: 30px;
        @include r.sp {
            font-size: 1.2rem;
            margin-bottom: 20px;
        }
    }
}

//--list---
//日本最高峰のバターフィナンシェ
.p-product-list-02 {
    margin-bottom: 100px;
    @include r.sp {
        margin-bottom: 35px;
    }
}

.c-product-list-02 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.c-product-list-02-item {
    width: 45.8%;
    @include r.sp {
        width: 100%;
    }
    &:not(:last-child) {
        @include r.sp {
            margin-bottom: 35px;
        }
    }
    &__image {
        margin-bottom: 30px;
        @include r.sp {
            margin-bottom: 15px;
        }
    }
    &__heading {
        font-size: 2rem;
        line-height: 30px;
        letter-spacing: 1.4px;
        margin-bottom: 23px;
        font-weight: 500;
        @include r.sp {
            margin-bottom: 15px;
            font-size: 1.2rem;
            line-height: 15px;
            letter-spacing: 0.84px;
        }
    }
    &__txt {
        font-size: 1.6rem;
        line-height: 30px;
        letter-spacing: 1.12px;
        @include r.sp {
            font-size: 1rem;
            line-height: 20px;
            letter-spacing: 0.7px;
        }
    }
}

//--list---
//--商品一覧（アレルギー表記付き）
.p-product-list-03 {
    margin-bottom: 100px;
    @include r.sp {
        margin-bottom: 35px;
    }
    //--ケーキおすすめ
    &--style-01 {
        background-color: #fff8f2;
        padding-top: 110px;
        padding-bottom: 100px;
        margin-bottom: 100px;
        @include r.sp {
            margin-bottom: 45px;
            padding-top: 50px;
            padding-bottom: 70px;
        }
    }
}

//商品一覧が複数ある時
.c-product-list-03-block {
    margin-bottom: 100px;
    @include r.sp {
        margin-bottom: 50px;
    }
    &__atten {
        font-size: 1.2rem;
        line-height: 30px;
        letter-spacing: 0.84px;
    }
}

.c-product-list-03 {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -60px;
    @include r.sp {
        margin-left: 0;
    }
    //アイテム下にmargin-bottomがある時
    &--style-01 {
        .c-product-list-03-item {
            margin-bottom: 50px;
            @include r.sp {
                margin-bottom: 40px !important;
            }
        }
    }
}

.c-product-list-03-item {
    margin-left: 60px;
    width: calc((100% - 180px) / 3);
    @include r.sp {
        margin-left: 0;
        width: 100%;
    }
    &:not(:last-child) {
        @include r.sp {
            margin-bottom: 20px;
        }
    }
    &__image {
        margin-bottom: 30px;
        -webkit-tap-highlight-color: transparent;
        -webkit-focus-ring-color: transparent;
        outline: none;
        box-shadow: none !important;
        position: relative;
        width: 100%;
        padding-top: 68.75%;
        /*比率を保持  880/1280 = 0.6875 or 68.75% */
        overflow: hidden;
        @include r.sp {
            margin-bottom: 15px;
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .c-image-link::before {
        z-index: 1;
    }
    &__heading {
        font-size: 2rem;
        line-height: 30px;
        letter-spacing: 1.4px;
        margin-bottom: 26px;
        line-height: 30px;
        font-weight: 500;
        @include r.sp {
            margin-bottom: 13px;
            font-size: 1.3rem;
            line-height: 20px;
            letter-spacing: 0.91px;
        }
    }
    &__price_wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        @include r.sp {
            margin-bottom: 8px;
        }
    }
    &__price {
        font-size: 1.6rem;
        line-height: 30px;
        letter-spacing: 1.12px;
        @include r.sp {
            margin-bottom: 4px;
            font-size: 1.1rem;
            line-height: 15px;
            letter-spacing: 0.77px;
        }
    }
    &__price_num {
        font-size: 2rem;
        line-height: 30px;
        letter-spacing: 1.4px;
        @include r.sp {
            font-size: 1.3rem;
            line-height: 15px;
            letter-spacing: 0.91px;
        }
    }
    &__amount {
        font-size: 1.6rem;
        line-height: 30px;
        letter-spacing: 1.12px;
        @include r.sp {
            font-size: 1.1rem;
            line-height: 15px;
            letter-spacing: 0.77px;
        }
    }
    &__label {
        width: 109px;
        height: 25px;
        line-height: 25px;
        font-size: 1.2rem;
        font-weight: 700;
        text-align: center;
        letter-spacing: 0.6px;
        color: v.$main-orange-color;
        background-color: v.$item-label-orange-color;
        @include r.sp {
            width: 90px;
            height: 17px;
            line-height: 17px;
            font-size: 0.9rem;
            letter-spacing: 0.9px;
        }
    }
    &__txt {
        margin-bottom: 20px;
        font-size: 1.6rem;
        line-height: 30px;
        letter-spacing: 1.12px;
        @include r.sp {
            margin-bottom: 10px;
            font-size: 1rem;
            line-height: 20px;
            letter-spacing: 0.7px;
        }
    }
    &__allergy {
        padding-bottom: 10px;
        border-bottom: 1px solid #e6e6e6;
    }
    &__allergy_heading {
        margin-bottom: 10px;
        font-size: 1.4rem;
        line-height: 26px;
        letter-spacing: 0.98px;
        position: relative;
        @include r.sp {
            margin-bottom: 5px;
            font-size: 1rem;
            line-height: 13px;
            letter-spacing: 0.7px;
        }
        &::after {
            content: '';
            width: 55%;
            height: 1px;
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(0, -50%);
            background-color: #e6e6e6;
            @include r.pc-1024 {
                width: 40%;
            }
            @include r.sp {
                width: 60%;
            }
        }
    }
    &__allergy_heading_txt {}
    &__allergy_detail {
        font-size: 1.4rem;
        line-height: 24px;
        letter-spacing: 0.98px;
        @include r.sp {
            font-size: 0.9rem;
            line-height: 16px;
            letter-spacing: 0.63px;
        }
    }
}

//productページの商品カテゴリー一覧
.p-product-category-list {
    margin-bottom: 100px;
    @include r.sp {
        margin-bottom: 35px;
    }
}

.c-category-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: -40px;
    @include r.sp {
        margin-left: 0;
    }
}

.c-category-list-item {
    display: flex;
    margin-left: 40px;
    width: calc((100% - 120px) / 2);
    border-bottom: 1px solid #ccc;
    padding-bottom: 50px;
    margin-bottom: 50px;
    display: flex;
    @include r.sp {
        margin-left: 0;
        width: 100%;
        padding-top: 0;
        padding-bottom: 25px;
        margin-bottom: 25px;
    }
    &:nth-of-type(1) {
        padding-top: 50px;
        border-top: 1px solid #ccc;
        @include r.sp {
            padding-top: 25px;
        }
    }
    &:nth-of-type(2) {
        padding-top: 50px;
        border-top: 1px solid #ccc;
        @include r.sp {
            border-top: none;
            padding-top: 0;
        }
    }
    &:last-child {
        @include r.sp {
            margin-bottom: 0;
        }
    }
    @include r.hover {
        .c-more-btn .c-arrow {
            transform: translateX(20px);
        }
        .c-more-btn::before {
            transform: scale(1);
        }
        @include r.hover {
            .c-image-link::before {
                opacity: 1;
            }
        }
    }
    &__left {
        flex-basis: 34%;
        flex-shrink: 0;
        @include r.sp {
            margin-left: 20px;
            flex-basis: 28%;
        }
    }
    &__right {
        margin-left: 10%;
        @include r.sp {
            margin-left: 10%;
            width: 90%;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            flex-direction: column;
        }
    }
    &__heading {
        font-size: 2rem;
        line-height: 30px;
        letter-spacing: 1.4px;
        margin-top: 55px;
        margin-bottom: 55px;
        line-height: 30px;
        font-weight: 500;
        @include r.sp {
            margin-top: 0;
            margin-bottom: 20px;
            font-size: 1.6rem;
            line-height: 25px;
        }
    }
    .c-image-link {
        &::before {
            border-radius: 6px;
        }
    }
    .c-more-btn {
        @include r.sp {
            margin-right: 25px;
        }
    }
}

//おやつアンカーリンク一覧--------
.p-page-link-list {
    margin-bottom: 100px;
    @include r.sp {
        margin-bottom: 50px;
    }
}

.c-page-link-list {
    display: flex;
    margin-left: -60px;
    @include r.sp {
        margin-left: 0;
        flex-wrap: wrap;
    }
    li {
        margin-left: 60px;
        width: calc((100% - 180px) / 3);
        font-size: 1.8rem;
        font-weight: 500;
        letter-spacing: 0.9px;
        line-height: 18px;
        padding-bottom: 20px;
        border-bottom: 1px solid v.$news-border-gray-color;
        transition: v.$anime-transition;
        &:first-child {
            @include r.sp {
                border-top: 1px solid v.$news-border-gray-color;
            }
        }
        @include r.sp {
            padding-top: 25px;
            margin-left: 0;
            width: 100%;
            font-size: 1.6rem;
            font-weight: 500;
            letter-spacing: 0.8px;
            line-height: 9px;
            padding-bottom: 25px;
        }
        @include r.hover {
            border-bottom: 1px solid v.$text-brown-color;
        }
        a {
            display: flex;
            align-items: center;
            color: #959595;
            position: relative;
            transition: v.$anime-transition;
            transform: translateY(-10px);
            @include r.sp {
                transform: translateY(0);
            }
            @include r.hover {
                transform: translateY(0);
            }
            &::after {
                content: '';
                width: 12px;
                height: 8px;
                display: inline-block;
                position: absolute;
                top: 50%;
                right: 20px;
                transform: translate(0, -50%);
                background-image: url(p.$path + 'images/common/arrow_bottom.svg');
                background-size: 100%;
                background-repeat: no-repeat;
                vertical-align: middle;
                transition: v.$anime-transition;
                @include r.sp {
                    width: 6px;
                    height: 4px;
                }
            }
        }
    }
    &__icon {
        display: inline-block;
        margin-right: 20px;
        margin-left: 10px;
        transition: v.$anime-transition;
        &--1 {
            width: 21px;
            @include r.sp {
                width: 12px;
            }
        }
        &--2 {
            width: 28px;
            @include r.sp {
                width: 12px;
            }
        }
        &--3 {
            width: 24px;
            @include r.sp {
                width: 12px;
            }
        }
        &--5 {
            width: 26px;
            @include r.sp {
                width: 12.5px;
            }
        }
        &--6 {
            width: 20px;
            @include r.sp {
                width: 10px;
            }
        }
        &--7 {
            width: 26px;
            @include r.sp {
                width: 13px;
            }
        }
        &--8 {
            width: 26px;
            @include r.sp {
                width: 12px;
            }
        }
        &--9 {
            width: 30px;
            @include r.sp {
                width: 12px;
            }
        }
    }
}


/*------------------------------------------------------------
news list
------------------------------------------------------------*/

//--ニュース一覧---
.p-news-list {
    margin-bottom: 100px;
    @include r.sp {
        margin-bottom: 40px;
    }
}

.c-news-list {
    li {
        border-bottom: 1px solid v.$news-border-gray-color;
    }
    a {
        padding: 30px 0;
        //line-height: 85px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 400;
        transition: v.$anime-transition;
        @include r.sp {
            flex-wrap: wrap;
            padding: 20px 0;
            display: flex;
            justify-content: flex-start;
        }
        @include r.hover {
            color: v.$main-orange-color;
        }
    }
    &__txt {
        width: 84%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include r.sp {
            width: 100%;
        }
        .c-arrow {
            opacity: 0;
            margin-left: 20px;
            width: 30px;
            transition: v.$anime-transition;
            transform: translateX(-10px);
            @include r.sp {
                opacity: 1;
                margin-left: 0;
                width: 17px;
                transform: translateX(0);
            }
        }
        @include r.hover {
            .c-arrow {
                opacity: 1;
                transform: translateX(0);
            }
            &::before {
                opacity: 1;
                transform: scale(0.16);
            }
        }
    }
    &__heading {
        width: 100%;
        margin-left: 20px;
        font-size: 1.6rem;
        letter-spacing: 1.1px;
        line-height: 25px;
        @include r.sp {
            width: 100%;
            margin-top: 8px;
            margin-left: 0;
            padding-right: 15px;
            font-size: 1.1rem;
            letter-spacing: 1.54px;
            line-height: 23px;
            position: relative;
        }
    }
    &__date {
        width: 15%;
        font-size: 1.6rem;
        letter-spacing: -0.8px;
        font-weight: 300;
        font-family: v.$font-lexend-exa;
        @include r.sp {
            width: 16%;
            margin-right: 15px;
            font-size: 1rem;
            letter-spacing: -1px;
            line-height: 13px;
        }
    }
}

//--label
.c-news-label {
    width: 95px;
    height: 25px;
    line-height: 25px;
    display: inline-block;
    //padding: 0 4px;
    font-size: 1.2rem;
    text-align: center;
    letter-spacing: 0.6px;
    color: #fff;
    border-radius: 4px;
    @include r.sp {
        width: 75px;
        height: 17px;
        line-height: 17px;
        font-size: 0.9rem;
        letter-spacing: 0.9px;
    }
    &--campaign {
        background-color: v.$label-pink-color;
    }
    &--news {
        background-color: v.$label-gold-color;
    }
    &--item {
        background-color: v.$label-orange-color;
    }
    &--event {
        background-color: v.$label-blue-color;
    }
    &--special {
        background-color: v.$label-purple-color;
    }
}
