@use '../settings/variable'as v;
@use '../settings/mixin'as r;
@use '../settings/_path'as p;

/*------------------------------------------------------------
form
------------------------------------------------------------*/

.c-product-intro--center {
    margin-top: 120px;
    @include r.sp {
        margin-top: 40px;
    }
}

//tel info
.c-contact-tel {
    max-width: 622px;
    margin: 0 auto 100px;
    padding: 25px 10px;
    border: 1px solid v.$contact-border-gray-color;
    border-radius: 10px;
    text-align: center;
    @include r.sp {
        margin: 0 auto 50px;
        padding: 15px 7px;
    }
    &__txt {
        margin-bottom: 20px;
        font-size: 1.4rem;
        letter-spacing: 0.7px;
        line-height: 32px;
        @include r.sp {
            font-size: 1rem;
            letter-spacing: 0.7px;
            line-height: 20px;
        }
    }
    &__block {
        font-size: 2.2rem;
        font-family: v.$font-lexend-exa;
        font-weight: 600;
        letter-spacing: 1.3px;
        line-height: 40px;
        @include r.sp {
            font-size: 1.6rem;
            letter-spacing: 0.7px;
            line-height: 20px;
        }
    }
    &__en {
        &::after {
            margin-left: 5px;
            content: ":";
        }
    }
    &__num {}
    &__time {
        font-size: 1.8rem;
        @include r.sp {
            display: block;
            font-size: 0.9rem;
        }
    }
}

//contact form
.c-mail-form {
    input {
        width: 360px;
        height: 70px;
        padding: 0 30px;
        border: 1px solid v.$news-border-gray-color;
        border-radius: 5px;
        font-size: 1.6rem;
        line-height: 24px;
        outline: 0;
        @include r.sp {
            width: 100%;
            height: 50px;
            padding: 0 20px;
            font-size: 1.2rem;
            line-height: 15px;
        }
        &::placeholder {
            color: v.$news-border-gray-color;
        }
        &:focus-visible {
            outline: none;
        }
        &[type="email"] {
            width: 100%;
        }
    }
    textarea {
        width: 100%;
        height: 400px;
        padding: 30px;
        border: 1px solid v.$news-border-gray-color;
        border-radius: 5px;
        font-size: 1.6rem;
        line-height: 24px;
        outline: 0;
        @include r.sp {
            height: 250px;
            padding: 10px 20px;
            font-size: 1.2rem;
            line-height: 15px;
        }
        &::placeholder {
            color: v.$news-border-gray-color;
        }
    }
    &__group {
        margin-bottom: 30px;
        display: flex;
        flex-wrap: wrap;
        @include r.sp {
            margin-bottom: 25px;
        }
    }
    &__title {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        font-size: 2rem;
        letter-spacing: 1.4px;
        line-height: 29px;
        font-weight: 700;
        @include r.sp {
            margin-bottom: 15px;
            font-size: 1.6rem;
            letter-spacing: 1.12px;
            line-height: 23px;
        }
    }
    &__label {
        margin-left: 14px;
        padding: 2px 5px;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 17px;
        background-color: v.$main-orange-color;
        color: #fff;
        @include r.sp {
            margin-left: 10px;
            padding: 2px 5px;
            font-size: 1.2rem;
        }
    }
    &__error {
        margin-left: 15px;
        font-size: 1.6rem;
        letter-spacing: 1.6px;
        line-height: 28px;
        font-weight: 700;
        color: #E50000;
        @include r.sp {
            margin-left: 5px;
            font-size: 1.1rem;
            letter-spacing: 1.1px;
            line-height: 14px;
        }
    }
    &__atten {
        margin-top: 15px;
        font-size: 1.6rem;
        line-height: 24px;
        @include r.sp {
            margin-top: 15px;
            font-size: 0.9rem;
            letter-spacing: 0.63px;
            line-height: 20px;
        }
    }
    &__name_group {
        input:first-child {
            margin-right: 30px;
            @include r.sp {
                margin-right: 0;
                margin-bottom: 10px;
            }
        }
    }
}

.is-invalid {
    border: 1px solid v.$main-orange-color !important;
}

//confirm
.c-mail-form-confirm {
    &__group {
        display: flex;
        align-items: center;
        padding: 19px 0;
        border-top: 1px solid v.$news-border-gray-color;
        @include r.pc-1024 {
            flex-wrap: wrap;
        }
        @include r.sp {
            padding: 11px 0;
        }
        &--last {
            border-bottom: 1px solid v.$news-border-gray-color;
        }
    }
    &__title {
        width: 30%;
        display: flex;
        align-items: center;
        font-size: 2rem;
        letter-spacing: 1.4px;
        line-height: 50px;
        font-weight: 700;
        @include r.sp {
            width: 100%;
            margin-bottom: 10px;
            font-size: 1.1rem;
            letter-spacing: 0.77px;
            line-height: 25px;
        }
    }
    &__data {
        font-size: 2rem;
        letter-spacing: 1.4px;
        line-height: 50px;
        @include r.sp {
            font-size: 1.1rem;
            letter-spacing: 0.77px;
            line-height: 25px;
        }
    }
}

//btn for confirm page
.p-mail-form__btns {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    @include r.sp {
        margin-top: 50px;
        flex-direction: column;
    }
    .c-mail-form-btn {
        width: 43%;
        @include r.sp {
            width: 100%;
            max-width: 235px;
            margin: 0 auto;
        }
        &:last-child {
            @include r.sp {
                margin-top: 50px;
            }
        }
    }
}

//btn for form page
.p-mail-form-btn {
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
    width: 470px;
    @include r.sp {
        margin-top: 50px;
        width: 100%;
        max-width: 235px;
    }
}

//btn common
.c-mail-form-btn {
    input {
        width: 100%;
        height: 100px;
        font-size: 2.2rem;
        letter-spacing: 1.1px;
        line-height: 32px;
        font-weight: 700;
        text-align: center;
        color: v.$text-brown-color;
        border-radius: 0;
        border: 1px solid v.$text-brown-color;
        transition: v.$anime-transition;
        @include r.sp {
            height: 50px;
            font-size: 1.6rem;
            letter-spacing: 0.8px;
            line-height: 23px;
        }
        @include r.hover {
            color: #fff;
            background-color: v.$main-orange-color;
            border: 1px solid v.$main-orange-color;
        }
    }
}

//link btn for thanks page
.p-form-link-btn {
    margin: 0 auto;
    width: 470px;
    @include r.sp {
        width: 100%;
        max-width: 235px;
    }
}

.c-form-link-btn {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.2rem;
    letter-spacing: 1.1px;
    line-height: 32px;
    font-weight: 700;
    text-align: center;
    color: v.$text-brown-color;
    border-radius: 0;
    border: 1px solid v.$text-brown-color;
    transition: v.$anime-transition;
    @include r.sp {
        width: 100%;
        height: 50px;
        font-size: 1.6rem;
        letter-spacing: 0.8px;
        line-height: 23px;
    }
    @include r.hover {
        color: #fff;
        background-color: v.$main-orange-color;
        border: 1px solid v.$main-orange-color;
    }
}
