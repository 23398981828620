@use '../settings/variable'as v;
@use '../settings/mixin'as r;
@use '../settings/_path'as p;

/*------------------------------------------------------------
navi
------------------------------------------------------------*/

//pagination---------------
.c-pagenavi {
    display: flex;
    justify-content: center;
    font-size: 1.6rem;
    letter-spacing: 0.8px;
    line-height: 32px;
    font-family: v.$font-lexend-exa;
    font-weight: 300;
    @include r.sp {
        font-size: 1.1rem;
        letter-spacing: 0.6px;
        line-height: 20px;
    }
    a {
        @include r.hover {
            color: v.$main-orange-color;
        }
    }
    &__numbers {
        margin: 0 25px;
        transition: v.$anime-transition;
        @include r.sp {
            margin: 0 8px;
        }
        &--current {
            font-weight: 400;
        }
        &--prev {
            margin-right: 30px;
            display: inline-block;
            position: relative;
            padding-left: 30px;
            @include r.sp {
                font-size: 0.9rem;
                padding-left: 15px;
                margin-right: 5px;
            }
            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                width: 20px;
                height: 1px;
                background-color: v.$text-brown-color;
                transition: v.$anime-transition;
                @include r.sp {
                    width: 12px;
                }
            }
            &::after {
                content: "";
                position: absolute;
                left: -1px;
                top: 52%;
                transform: translateY(-50%);
                width: 0;
                height: 0;
                border-style: solid;
                border-top: 3px solid transparent;
                border-bottom: 3px solid transparent;
                border-right: 7px solid v.$text-brown-color;
                border-left: 0;
                transition: v.$anime-transition;
                @include r.sp {
                    border-top: 2px solid transparent;
                    border-bottom: 2px solid transparent;
                    border-right: 4px solid v.$text-brown-color;
                }
            }
            @include r.hover {
                &::before {
                    transform: translate(-20px, -50%);
                }
                &::after {
                    transform: translate(-20px, -50%);
                }
            }
        }
        &--next {
            margin-left: 30px;
            display: inline-block;
            position: relative;
            padding-right: 30px;
            @include r.sp {
                font-size: 0.9rem;
                padding-right: 15px;
                margin-left: 5px;
            }
            &::before {
                content: "";
                position: absolute;
                top: 50%;
                right: 0;
                width: 20px;
                height: 1px;
                background-color: v.$text-brown-color;
                transition: v.$anime-transition;
                @include r.sp {
                    width: 12px;
                }
            }
            &::after {
                content: "";
                position: absolute;
                right: -1px;
                top: 52%;
                transform: translateY(-50%);
                width: 0;
                height: 0;
                border-style: solid;
                border-top: 3px solid transparent;
                border-bottom: 3px solid transparent;
                border-left: 7px solid v.$text-brown-color;
                border-right: 0;
                transition: v.$anime-transition;
                @include r.sp {
                    border-top: 2px solid transparent;
                    border-bottom: 2px solid transparent;
                    border-left: 4px solid v.$text-brown-color;
                }
            }
            @include r.hover {
                &::before {
                    transform: translate(30px, -50%);
                }
                &::after {
                    transform: translate(30px, -50%);
                }
            }
        }
    }
}

//c-single-page-navi-------
.c-single-page-navi {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    letter-spacing: 0.8px;
    @include r.sp {
        font-size: 1.2rem;
        letter-spacing: 0.6px;
        line-height: 20px;
    }
    &__top {
        a {
            width: 289px;
            height: 58px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.6rem;
            line-height: 50px;
            font-family: v.$font-zenKaku;
            font-weight: 500;
            position: relative;
            transition: v.$anime-transition;
            @include r.sp {
                width: 144px;
                height: 29px;
                font-size: 0.8rem;
                line-height: 25px;
            }
            &::before,
            &::after {
                content: '';
                width: 9px;
                height: 100%;
                position: absolute;
            }
            &::before {
                border-left: solid 1px v.$text-brown-color;
                border-top: solid 1px v.$text-brown-color;
                border-bottom: solid 1px v.$text-brown-color;
                top: 0;
                left: 0;
            }
            &::after {
                border-right: solid 1px v.$text-brown-color;
                border-top: solid 1px v.$text-brown-color;
                border-bottom: solid 1px v.$text-brown-color;
                top: 0;
                right: 0;
            }
            @include r.hover {
                color: v.$main-orange-color;
            }
        }
    }
    &__link {
        font-family: v.$font-lexend-exa;
        font-weight: 300;
        letter-spacing: 0.9px;
        text-transform: uppercase;
        transition: v.$anime-transition;
        @include r.hover {
            color: v.$main-orange-color;
        }
        &--prev {
            margin-right: 90px;
            display: inline-block;
            position: relative;
            padding-left: 30px;
            @include r.sp {
                padding-left: 20px;
                margin-right: 15px;
            }
            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                width: 20px;
                height: 1px;
                background-color: v.$text-brown-color;
                transition: v.$anime-transition;
                @include r.sp {
                    width: 12px;
                }
            }
            &::after {
                content: "";
                position: absolute;
                left: -1px;
                top: 52%;
                transform: translateY(-50%);
                width: 0;
                height: 0;
                border-style: solid;
                border-top: 3px solid transparent;
                border-bottom: 3px solid transparent;
                border-right: 7px solid v.$text-brown-color;
                border-left: 0;
                transition: v.$anime-transition;
                @include r.sp {
                    border-top: 2px solid transparent;
                    border-bottom: 2px solid transparent;
                    border-right: 4px solid v.$text-brown-color;
                }
            }
            @include r.hover {
                &::before {
                    transform: translate(-20px, -50%);
                }
                &::after {
                    transform: translate(-20px, -50%);
                }
            }
        }
        &--next {
            margin-left: 90px;
            display: inline-block;
            position: relative;
            padding-right: 30px;
            @include r.sp {
                padding-right: 20px;
                margin-left: 15px;
            }
            &::before {
                content: "";
                position: absolute;
                top: 50%;
                right: 0;
                width: 20px;
                height: 1px;
                background-color: v.$text-brown-color;
                transition: v.$anime-transition;
                @include r.sp {
                    width: 12px;
                }
            }
            &::after {
                content: "";
                position: absolute;
                right: -1px;
                top: 52%;
                transform: translateY(-50%);
                width: 0;
                height: 0;
                border-style: solid;
                border-top: 3px solid transparent;
                border-bottom: 3px solid transparent;
                border-left: 7px solid v.$text-brown-color;
                border-right: 0;
                transition: v.$anime-transition;
                @include r.sp {
                    border-top: 2px solid transparent;
                    border-bottom: 2px solid transparent;
                    border-left: 4px solid v.$text-brown-color;
                }
            }
            @include r.hover {
                &::before {
                    transform: translate(30px, -50%);
                }
                &::after {
                    transform: translate(30px, -50%);
                }
            }
        }
    }
}
