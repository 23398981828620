@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
block
------------------------------------------------------------*/

.p-gift {
    .c-corporate-gift-link-block {
        margin-bottom: 200px;
        @include r.sp {
            margin-bottom: 100px;
        }
    }
}

.c-corporate-gift-link-block {
    display: block;
    @include r.hover {
        .c-more-btn .c-arrow {
            transform: translateX(20px);
        }
        .c-more-btn::before {
            transform: scale(1);
        }
        @include r.hover {
            .c-image-link::before {
                opacity: 1;
            }
        }
    }
    &__image {
        margin-bottom: 37px;
        @include r.sp {
            margin-bottom: 25px;
        }
    }
    &__heading {
        font-size: 2.6rem;
        margin-bottom: 10px;
        font-weight: 500;
        @include r.sp {
            font-size: 1.6rem;
            margin-bottom: 15px;
        }
    }
    &__info {
        display: flex;
        justify-content: space-between;
        @include r.sp {
            flex-direction: column;
        }
    }
    &__txt {
        font-size: 1.6rem;
        font-weight: 400;
        @include r.sp {
            font-size: 1.2rem;
            margin-bottom: 30px;
        }
    }
}

//company link--
//top bnr link-----
.p-top-shop-bnr-block {
    margin-bottom: 60px;
    @include r.sp {
        margin-bottom: 50px;
    }
}

.c-shop-bnr-block {
    display: block;
    width: 100%;
    height: 250px;
    background-image: url(p.$path + 'images/top/top_bnr_bg_pc_1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    @include r.sp {
        height: 334px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        background-image: url(p.$path + 'images/top/top_bnr_bg_sp_1.jpg');
        background-size: cover;
    }
    @include r.hover {
        .c-more-btn .c-arrow {
            transform: translateX(20px);
            &--style-01 {
                transform: translateX(10px);
            }
        }
        .c-more-btn::before {
            transform: scale(1);
        }
    }
    &__detail {
        width: 53%;
        height: 200px;
        margin-bottom: 30px;
        padding: 0 20px 0 45px;
        position: absolute;
        top: 50%;
        right: 17px;
        transform: translateY(-50%);
        display: flex;
        justify-content: center;
        flex-direction: column;
        background-color: rgba(255, 255, 255, 0.9);
        @include r.sp {
            width: 78%;
            height: 191px;
            padding: 0;
            position: static;
            top: auto;
            right: auto;
            align-items: center;
            transform: none;
        }
    }
    &__ttl {
        font-size: 2.6rem;
        margin-bottom: 6px;
        font-weight: 500;
        @include r.sp {
            font-size: 1.6rem;
            text-align: center;
            line-height: 25px;
        }
    }
    &__txt {
        margin-bottom: 30px;
        font-size: 1.6rem;
        @include r.sp {
            margin-bottom: 15px;
            font-size: 1.2rem;
            line-height: 25px;
        }
    }
}

//top oiwai link
.p-top-gift-bnr-block {
    margin-bottom: 60px;
    @include r.sp {
        margin-bottom: 50px;
    }
}

.c-gift-bnr-block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 250px;
    border: 1px solid #707070;
    transition: v.$anime-transition;
    @include r.sp {
        height: 300px;
        flex-direction: column;
        justify-content: flex-start;
    }
    @include r.hover {
        .c-more-btn .c-arrow {
            transform: translateX(20px);
        }
        .c-more-btn::before {
            transform: scale(1);
        }
    }
    &__left {
        width: 50%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        @include r.sp {
            margin-bottom: 30px;
            width: 100%;
            height: 140px;
        }
    }
    &__right {
        width: 50%;
        display: flex;
        align-items: center;
        flex-direction: column;
        @include r.sp {
            width: 100%;
        }
    }
    &__sub_ttl {
        font-size: 2rem;
        letter-spacing: 1.2px;
        line-height: 60px;
        font-weight: 500;
        @include r.sp {
            font-size: 1rem;
            letter-spacing: 0.6px;
            line-height: 30px;
        }
    }
    &__ttl {
        margin-bottom: 40px;
        font-size: 4.8rem;
        letter-spacing: 2.88px;
        line-height: 60px;
        font-weight: 600;
        text-align: center;
        @include r.pc-1024 {
            font-size: 3rem;
        }
        @include r.sp {
            margin-bottom: 25px;
            font-size: 2.4rem;
            text-align: center;
            letter-spacing: 1.44px;
            line-height: 30px;
        }
    }
    .c-btn-start {
        @include r.sp {
            display: flex;
            justify-content: center;
        }
    }
    &--gift-01 {
        .c-gift-bnr-block__left {
            background-image: url(p.$path + 'images/top/top_bnr_oiwai_patisserie_pc.jpg');
            @include r.sp {
                background-image: url(p.$path + 'images/top/top_bnr_oiwai_patisserie_sp.jpg');
            }
        }
    }
    &--gift-02 {
        .c-gift-bnr-block__left {
            background-image: url(p.$path + 'images/top/top_bnr_oiwai_gift_pc.jpg');
            @include r.sp {
                background-image: url(p.$path + 'images/top/top_bnr_oiwai_gift_sp.jpg');
            }
        }
    }
    @include r.hover {
        background-color: #D8D8D8;
    }
}

//お取り寄せリンク-----
.p-order-block {
    margin-bottom: 200px;
    @include r.sp {
        margin-bottom: 50px;
    }
}

.c-order-block {
    display: block;
    width: 100%;
    height: 455px;
    background-image: url(p.$path + 'images/product/financier/link_pc.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    @include r.sp {
        height: auto;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        background-image: url(p.$path + 'images/product/financier/link_sp.jpg');
        background-size: 100%;
    }
    @include r.hover {
        .c-more-btn .c-arrow {
            transform: translateX(20px);
        }
        .c-more-btn::before {
            transform: scale(1);
        }
    }
    &__detail {
        width: 72%;
        height: 264px;
        padding-left: 70px;
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        background-color: rgba(255, 255, 255, 0.9);
        @include r.sp {
            margin-top: 150px;
            width: 86%;
            height: 250px;
            padding: 0px 25px;
            position: static;
            top: auto;
            right: auto;
            transform: none;
        }
    }
    &__ttl {
        margin-top: -30px;
        font-size: 2.6rem;
        font-weight: 500;
        line-height: 50px;
        @include r.sp {
            margin-top: 40px;
            margin-bottom: 10px;
            font-size: 1.5rem;
            text-align: center;
            line-height: 25px;
        }
    }
    &__txt {
        font-size: 1.6rem;
        line-height: 30px;
        @include r.sp {
            margin-bottom: 15px;
            font-size: 1.2rem;
            line-height: 25px;
        }
    }
    &__link {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 250px;
        @include r.sp {
            position: static;
            bottom: auto;
            right: auto;
        }
    }
}

//--contact ---------------
.p-contact-area {}

.c-contact-area {
    width: 100%;
    height: 576px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    @include r.sp {
        height: 339px;
    }
    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background-color: rgba(83, 48, 37, 0.66);
        z-index: -1;
    }
    &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background-image: url(p.$path + 'images/common/contac_bg_pc.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -2;
        @include r.sp {
            background-image: url(p.$path + 'images/common/contac_bg_sp.jpg');
        }
    }
    &__detail {
        display: flex;
        align-items: center;
        flex-direction: column;
        color: #fff;
        max-width: 1080px;
        margin: 0 auto;
        @include r.pc-1440 {
            padding-left: 2%;
            padding-right: 2%;
        }
        @include r.sp {
            width: 100%;
            padding-left: 8%;
            padding-right: 8%;
        }
    }
    &__txt {
        margin-bottom: 24px;
        font-size: 2rem;
        letter-spacing: 1.6px;
        font-weight: 400;
        @include r.sp {
            margin-bottom: 0px;
            font-size: 1.2rem;
            line-height: 25px;
            letter-spacing: 1.92px;
            text-align: center;
        }
    }
    &__tel {
        font-size: 3.8rem;
        font-weight: 400;
        font-family: v.$font-lexend-exa;
        letter-spacing: 2px;
        text-align: center;
        @include r.sp {
            font-size: 2.4rem;
        }
    }
    &__tel_link {
        pointer-events: none;
        cursor: default;
        text-decoration: none;
        @include r.sp {
            pointer-events: all;
            cursor: pointer;
        }
    }
    &__tel_en {
        margin-right: 10px;
        text-transform: uppercase;
        font-size: 2.2rem;
        letter-spacing: 1.76px;
        vertical-align: middle;
        @include r.sp {
            font-size: 1.3rem;
        }
    }
    &__tel_time {
        margin-bottom: 40px;
        font-size: 1.6rem;
        font-weight: 400;
        letter-spacing: 1.28px;
        font-family: v.$font-zenKaku;
        @include r.sp {
            margin-bottom: 35px;
            font-size: 1.1rem;
        }
    }
    &__link {
        width: 258px;
        height: 68px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
        border: 1px solid #fff;
        text-align: center;
        transition: v.$anime-transition;
        @include r.hover {
            background-color: rgba(0, 0, 0, 0.3);
        }
        @include r.sp {
            width: 150px;
            height: 42px;
            font-size: 1.3rem;
        }
    }
}

//----------------------
//----------------
.p-point-block-01 {
    background-color: #fff8f2;
    padding-top: 110px;
    padding-bottom: 130px;
    margin-bottom: 70px;
    @include r.sp {
        margin-bottom: 45px;
        padding-top: 50px;
        padding-bottom: 70px;
    }
}

.c-point-block-01 {}

.c-point-block-01-item {
    display: flex;
    justify-content: space-between;
    @include r.sp {
        flex-direction: column;
    }
    //--reverse
    &--reverse {
        flex-direction: row-reverse;
        @include r.sp {
            flex-direction: column;
        }
        .c-point-block-01-item__right {
            padding-left: 0;
            padding-right: 4%;
        }
        .c-point-block-01-item__image {
            margin-left: auto;
        }
        .c-point-block-01-item__image::after {
            left: auto;
            z-index: 1;
            right: 23px;
        }
    }
    &:not(:last-child) {
        margin-bottom: 120px;
        @include r.sp {
            margin-bottom: 50px;
        }
    }
    &__left {
        width: 50%;
        @include r.sp {
            width: 100%;
        }
    }
    &__right {
        width: 50%;
        margin-top: 100px;
        padding-left: 4%;
        display: flex;
        @include r.sp {
            width: 100%;
            margin-top: 35px;
            padding-left: 0;
        }
    }
    &__image {
        width: 88%;
        position: relative;
        @include r.sp {
            width: 76%;
        }
        &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            left: 23px;
            bottom: -23px;
            border: 2px solid #fff;
            z-index: 1;
        }
    }
    &__detail {
        @include r.sp {
            width: 100%;
        }
        &--2 {
            width: 88%;
            @include r.sp {
                width: 100%;
            }
        }
    }
    &__point {
        margin-bottom: 55px;
        display: block;
        color: v.$main-orange-color;
        font-size: 1.4rem;
        letter-spacing: -0.7px;
        font-weight: 300;
        line-height: 37px;
        font-family: v.$font-lexend-exa;
        @include r.sp {
            margin-bottom: 25px;
            font-size: 0.8rem;
            letter-spacing: -0.4px;
            font-weight: 300;
            line-height: 18.5px;
        }
    }
    &___no {
        display: block;
        font-size: 4.3rem;
        letter-spacing: -2.15px;
        font-weight: 200;
        @include r.sp {
            margin-left: 5px;
            display: inline-block;
            font-size: 2.15rem;
            letter-spacing: -1.075px;
        }
    }
    &__heading {
        margin-bottom: 40px;
        font-size: 3rem;
        line-height: 50px;
        font-weight: 500;
        @include r.sp {
            margin-bottom: 5px;
            font-size: 1.5rem;
            line-height: 25px;
        }
    }
    &__txt {
        font-size: 1.8rem;
        letter-spacing: 1.18px;
        font-weight: 500;
        line-height: 40px;
        @include r.sp {
            font-size: 1.2rem;
            letter-spacing: 0.84px;
            font-weight: 500;
            line-height: 25px;
        }
    }
}

//----------------------
//----------------
.p-point-block-02 {
    background-color: #fff;
    padding: 90px 100px 200px;
    @include r.sp {
        padding: 40px 7% 30px;
    }
}

.c-point-block-02-item {
    display: flex;
    justify-content: space-between;
    @include r.sp {
        flex-direction: column-reverse;
    }
    //--reverse
    &--reverse {
        flex-direction: row-reverse;
        @include r.sp {
            flex-direction: column-reverse;
        }
        .c-point-block-02-item__right {
            padding-left: 0;
            padding-right: 4%;
            @include r.sp {
                margin-bottom: 50px;
                padding-right: 0;
            }
        }
        .c-point-block-02-item__image {
            margin-left: auto;
        }
        .c-point-block-02-item__image::after {
            left: auto;
            z-index: 1;
            right: 23px;
        }
    }
    &:not(:last-child) {
        margin-bottom: 90px;
        @include r.sp {
            margin-bottom: 45px;
        }
    }
    &__left {
        width: 50%;
        @include r.sp {
            width: 100%;
        }
    }
    &__right {
        width: 50%;
        padding-left: 4%;
        display: flex;
        @include r.sp {
            margin-bottom: 50px;
            width: 100%;
            padding-left: 0;
        }
    }
    &__image {
        width: 82%;
        position: relative;
        @include r.sp {
            width: 100%;
        }
    }
    &__detail {
        @include r.sp {
            width: 100%;
        }
        &--2 {
            width: 88%;
            @include r.sp {
                width: 100%;
            }
        }
    }
    &__point {
        margin-bottom: 40px;
        display: block;
        color: v.$main-orange-color;
        font-size: 1.4rem;
        letter-spacing: -0.7px;
        font-weight: 300;
        line-height: 37px;
        font-family: v.$font-lexend-exa;
        @include r.sp {
            margin-bottom: 20px;
            font-size: 0.8rem;
            letter-spacing: -0.4px;
            font-weight: 300;
            line-height: 18.5px;
        }
    }
    &___no {
        display: block;
        font-size: 4.3rem;
        letter-spacing: -2.15px;
        font-weight: 200;
        @include r.sp {
            margin-left: 5px;
            display: inline-block;
            font-size: 2.15rem;
            letter-spacing: -1.075px;
        }
    }
    &__heading {
        margin-bottom: 40px;
        font-size: 3rem;
        line-height: 50px;
        font-weight: 500;
        @include r.sp {
            margin-bottom: 10px;
            font-size: 1.5rem;
            line-height: 25px;
        }
    }
    &__txt {
        // margin-bottom: 80px;
        font-size: 1.8rem;
        letter-spacing: 1.18px;
        font-weight: 500;
        line-height: 40px;
        @include r.sp {
            //margin-bottom: 40px;
            font-size: 1.2rem;
            letter-spacing: 0.84px;
            font-weight: 500;
            line-height: 25px;
        }
    }
}

//----------------------
//----------------
.p-point-block-03 {}

.c-point-block-03 {
    position: relative;
    z-index: 0;
    &:before {
        content: "";
        display: block;
        width: 77%;
        height: 66%;
        position: absolute;
        top: 10%;
        left: 50%;
        transform: translate(-50%, 0);
        background-color: #fff;
        z-index: -1;
        @include r.sp {
            height: 93%;
            top: 10%;
            left: auto;
            right: 0;
            transform: translate(0, 0);
        }
    }
    &--style-01 {
        &:before {
            background-color: #fff8f2;
        }
    }
}

.c-point-block-03-item {
    display: flex;
    justify-content: space-between;
    @include r.sp {
        flex-direction: column;
        position: relative;
    }
    &:before {
        @include r.sp {
            content: "";
            margin-left: -10%;
            display: block;
            z-index: -2;
            width: 100vw;
            height: 100%;
            position: absolute;
            background-image: url(p.$path + 'images/concept/bg_line_sp.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
    }
    &:not(:last-child) {
        margin-bottom: 90px;
        @include r.sp {
            margin-bottom: 45px;
        }
    }
    &--1 {
        &:before {
            @include r.sp {
                top: -180px;
            }
        }
    }
    &--2 {
        &:before {
            @include r.sp {
                top: 0px;
            }
        }
    }
    &__left {
        width: 50%;
        padding-right: 4%;
        display: flex;
        flex-wrap: wrap;
        @include r.sp {
            margin-bottom: 25px;
            width: 100%;
            padding-right: 0;
        }
    }
    &__right {
        width: 50%;
        @include r.sp {
            width: 100%;
        }
    }
    &__image {
        width: 87%;
        margin-left: auto;
        position: relative;
        @include r.sp {
            width: 87%;
            margin-left: 0;
        }
    }
    &__point {
        margin-bottom: 40px;
        display: block;
        color: v.$main-orange-color;
        font-size: 1.4rem;
        letter-spacing: -0.7px;
        font-weight: 300;
        line-height: 37px;
        font-family: v.$font-lexend-exa;
        @include r.sp {
            margin-bottom: 20px;
            font-size: 0.8rem;
            letter-spacing: -0.4px;
            font-weight: 300;
            line-height: 18.5px;
        }
    }
    &___no {
        display: block;
        font-size: 4.3rem;
        letter-spacing: -2.15px;
        font-weight: 200;
        @include r.sp {
            margin-left: 5px;
            display: inline-block;
            font-size: 2.15rem;
            letter-spacing: -1.075px;
        }
    }
    &__heading {
        margin-bottom: 40px;
        font-size: 3rem;
        line-height: 50px;
        font-weight: 500;
        @include r.sp {
            margin-bottom: 10px;
            font-size: 1.5rem;
            line-height: 25px;
        }
    }
    &__txt {
        font-size: 1.8rem;
        letter-spacing: 1.18px;
        font-weight: 500;
        line-height: 40px;
        @include r.sp {
            font-size: 1.2rem;
            letter-spacing: 0.84px;
            font-weight: 500;
            line-height: 25px;
        }
    }
    &__catch {
        display: flex;
        justify-content: space-between;
        margin-top: 190px;
        @include r.sp {
            margin-top: 10px;
        }
    }
    &__catch_image {
        width: 46%;
    }
}

//----------------------
//----------------
.c-point-block-04 {
    position: relative;
    z-index: 0;
    &:before {
        content: "";
        display: block;
        width: 77%;
        height: 95%;
        position: absolute;
        top: 12%;
        left: 50%;
        transform: translate(-50%, 0);
        background-color: #fff8f2;
        z-index: -1;
        @include r.sp {
            height: 95%;
            top: 8%;
            left: auto;
            right: 0;
            transform: translate(0, 0);
        }
    }
}

.c-point-block-04-item {
    display: flex;
    justify-content: space-between;
    @include r.sp {
        flex-direction: column-reverse;
        align-items: center;
        position: relative;
    }
    &:before {
        @include r.sp {
            content: "";
            margin-left: -10%;
            display: block;
            z-index: -1;
            width: 100vw;
            height: 100%;
            position: absolute;
            background-image: url(p.$path + 'images/concept/bg_line_sp.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
    }
    &--1 {
        &:before {
            @include r.sp {
                top: -80px;
            }
        }
    }
    &--2 {
        &:before {
            @include r.sp {
                top: -15px;
            }
        }
    }
    &:not(:last-child) {
        margin-bottom: 90px;
        @include r.sp {
            margin-bottom: 45px;
        }
    }
    &__left {
        width: 50%;
        @include r.sp {
            width: 100%;
        }
    }
    &__right {
        width: 50%;
        padding-left: 4%;
        display: flex;
        flex-wrap: wrap;
        @include r.sp {
            margin-bottom: 25px;
            width: 85%;
            padding-left: 0;
        }
    }
    &__image {
        width: 87%;
        position: relative;
        @include r.sp {
            width: 85%;
            margin: 0 auto;
        }
    }
    &__point {
        margin-bottom: 40px;
        display: block;
        color: v.$main-orange-color;
        font-size: 1.4rem;
        letter-spacing: -0.7px;
        font-weight: 300;
        line-height: 37px;
        font-family: v.$font-lexend-exa;
        @include r.sp {
            margin-bottom: 20px;
            font-size: 0.8rem;
            letter-spacing: -0.4px;
            font-weight: 300;
            line-height: 18.5px;
        }
    }
    &___no {
        display: block;
        font-size: 4.3rem;
        letter-spacing: -2.15px;
        font-weight: 200;
        @include r.sp {
            margin-left: 5px;
            display: inline-block;
            font-size: 2.15rem;
            letter-spacing: -1.075px;
        }
    }
    &__heading {
        margin-bottom: 40px;
        font-size: 3rem;
        line-height: 50px;
        font-weight: 500;
        @include r.sp {
            margin-bottom: 10px;
            font-size: 1.5rem;
            line-height: 25px;
        }
    }
    &__txt {
        font-size: 1.8rem;
        letter-spacing: 1.18px;
        font-weight: 500;
        line-height: 40px;
        @include r.sp {
            font-size: 1.2rem;
            letter-spacing: 0.84px;
            font-weight: 500;
            line-height: 25px;
        }
    }
}
