@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
slider
------------------------------------------------------------*/

.c-hero_logo {
    // position: absolute;
    width: 377px;
    height: 213px;
    z-index: 1;
    left: 21%;
    &--sticky {
        top: 17%;
        position: fixed;
    }
    &--about {
        position: absolute;
        @include r.sp {
            margin-top: -60px;
        }
    }
    @include r.pc-1280 {
        width: 300px;
        // height: 213px;
    }
    @include r.pc-1024 {
        width: 250px;
    }
    @include r.sp {
        width: 188px;
        height: 106px;
        left: 7%;
    }
}

.p-top {
    main {
        padding-top: 0;
    }
}

.p-mv {
    position: relative;
}

//--
.c-mv-arrow {
    position: absolute;
    top: 20%;
    left: 12%;
    @include r.sp {
        top: 48%;
        left: 8%;
    }
}

.c-scroll-down {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 1.2rem;
    line-height: 15px;
    font-family: v.$font-lexend-exa;
    font-weight: 500;
    @include r.sp {
        font-size: 0.6rem;
        line-height: 7px;
    }
    &__txt {
        writing-mode: vertical-rl;
        transform: rotate(180deg);
        transform-origin: center;
    }
    &__arrow {
        margin-top: 20px;
        animation: scrollAnimation 2s infinite;
        display: inline-block;
        position: relative;
        width: 1px;
        height: 90px;
        background-color: v.$text-brown-color;
        @include r.sp {
            margin-top: 10px;
            height: 43px;
        }
        &::after {
            content: "";
            position: absolute;
            right: 0;
            bottom: -1px;
            transform: translateX(40%) translateY(0);
            width: 0;
            height: 0;
            border-style: solid;
            border-left: 3px solid transparent;
            border-right: 3px solid transparent;
            border-top: 7px solid v.$text-brown-color;
            border-bottom: 0;
            @include r.sp {
                border-left: 2px solid transparent;
                border-right: 2px solid transparent;
                border-top: 4px solid v.$text-brown-color;
                border-bottom: 0;
            }
        }
    }
}

@keyframes scrollAnimation {
    0% {
        transform: translateX(-50%) translateY(0);
    }
    50% {
        transform: translateX(-50%) translateY(30px);
    }
    100% {
        transform: translateX(-50%) translateY(0);
    }
}

///----
.p-mv-slider {
    position: relative;
    margin-left: auto;
    width: 68% !important;
    height: 100vh;
    /* カスタムプロパティ未対応ブラウザ用のフォールバック */
    // height: calc(var(--vh, 1vh) * 100);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @include r.sp {
        width: 72% !important;
        // height: auto;
        flex-direction: column-reverse;
        justify-content: center;
    }
}

.c-hero-slider {
    margin-top: auto; // 追加: スライダーを下寄せ
    width: 100%;
    // height: 70%; // 追加: スライダーの高さを63vhに設定
    overflow: hidden;
    position: relative;
    @include r.sp {
        margin-top: 0;
    }
    &__slide {
        width: 100%;
    }
    &__txt {
        color: #fff;
        font-size: 2rem;
        line-height: 40px;
        letter-spacing: 1.6px;
        position: absolute;
        z-index: 2;
        font-weight: 500;
        @include r.sp {
            font-size: 1.2rem;
            line-height: 25px;
            letter-spacing: 0.96px;
        }
        &__big {
            font-size: 2.6rem;
            line-height: 48px;
            letter-spacing: 2.08px;
            @include r.sp {
                font-size: 1.3rem;
                line-height: 23px;
                letter-spacing: 1.04px;
            }
        }
        &__small {
            font-size: 1.3rem;
            line-height: 36px;
            letter-spacing: 1.28px;
            @include r.sp {
                font-size: 0.8rem;
                line-height: 18px;
                letter-spacing: 0.64px;
            }
        }
        &--1 {
            bottom: 13%;
            left: 16%;
            @include r.sp {
                bottom: 9%;
            }
        }
        &--2 {
            left: 4%;
            top: 43%;
            @include r.sp {
                top: auto;
                bottom: 9%;
                left: 16%;
            }
        }
        &--3 {
            color: v.$text-brown-color;
            right: 6%;
            bottom: 11%;
            @include r.sp {
                color: #fff;
                right: auto;
                left: 16%;
                bottom: 9%;
            }
        }
        &--4 {
            color: v.$text-brown-color;
            left: 8%;
            bottom: 8%;
            @include r.sp {}
        }
        &--5 {
            color: v.$text-brown-color;
            left: 8%;
            top: 25%;
            @include r.sp {
                left: 8%;
                top: 38%;
            }
        }
    }
}

.c-hero-slider-bottom {
    height: 7%; // 追加: スライダー下部の高さを7vhに設定
    display: flex;
    align-items: center;
    justify-content: center;
    @include r.sp {
        margin-right: 30px;
        margin-bottom: 10px;
        height: 2%;
        justify-content: flex-end;
    }
}

.c-hero-slider-custom-arrows {
    display: flex;
    align-items: center;
    margin-right: 70px;
    font-size: 1.4rem;
    line-height: 18px;
    font-family: v.$font-lexend-exa;
    font-weight: 500;
    @include r.sp {
        display: none;
    }
    &__prev_arrow {
        margin-right: 40px;
    }
}

.c-hero-slider-num {
    margin-right: 20px;
    font-size: 1.6rem;
    line-height: 20px;
    font-family: v.$font-lexend-exa;
    font-weight: 500;
    @include r.sp {
        margin-right: 10px;
        font-size: 0.8rem;
        line-height: 10px;
    }
    z-index: 5;
    &__now_count {
        color: v.$main-orange-color;
    }
}

.c-hero-slider-progress {
    width: 170px;
    height: 2px;
    background-color: v.$news-border-gray-color;
    @include r.sp {
        width: 85px;
    }
    span {
        display: block;
        width: 0px;
        height: 100%;
        background-color: v.$text-brown-color;
        transition: all 0s linear;
        &.active {
            width: 100%;
        }
    }
}
