@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
btn
------------------------------------------------------------*/

//page top btn
.p-page-top {
    position: fixed;
    right: 0;
    bottom: 15px;
    @include r.sp {
        bottom: 15px;
    }
}

.c-page-top-btn {
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    font-family: v.$font-lexend-exa;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 23px;
    @include r.sp {
        align-items: flex-start;
        flex-direction: column-reverse;
        font-size: 0.9rem;
        line-height: 11px;
    }
    &::after {
        content: '';
        margin-left: 15px;
        width: 70px;
        height: 2px;
        display: inline-block;
        background-color: v.$text-brown-color;
        transition: v.$anime-transition;
        transition: v.$anime-transition;
        @include r.sp {
            margin-bottom: 10px;
            width: 24px;
            margin-left: 0;
        }
    }
    @include r.hover {
        color: v.$main-orange-color;
        &::after {
            width: 56px;
            background-color: v.$main-orange-color;
        }
    }
}

//---
.c-arrow {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 1px;
    background-color: v.$text-brown-color;
    &::after {
        content: "";
        position: absolute;
        right: -1px;
        top: 50%;
        transform: translate(0, -50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-left: 7px solid v.$text-brown-color;
        border-right: 0;
        @include r.sp {
            right: -1px;
            width: 4px;
            height: 3px;
            border-top: 2px solid transparent;
            border-bottom: 2px solid transparent;
            border-left: 4px solid v.$text-brown-color;
        }
    }
    &--style-01 {
        background-color: #fff;
        &::after {
            border-bottom: 2px solid transparent;
            border-left: 4px solid #fff;
        }
    }
}

//------------------
.c-btn-end {
    display: flex;
    justify-content: flex-end;
    // margin-left: auto;
}

.c-more-btn {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 1.6rem;
    font-family: v.$font-lexend-exa;
    text-transform: uppercase;
    transition: v.$anime-transition;
    position: relative;
    //japanese btn
    &--style-01 {
        font-family: v.$font-zenKaku;
        font-weight: 700;
    }
    @include r.sp {
        font-size: 0.9rem;
    }
    &::before {
        content: '';
        width: 25px;
        height: 25px;
        transform: scale(0.16);
        display: block;
        background-color: v.$main-orange-color;
        border-radius: 50%;
        margin-right: 10px;
        transition: v.$anime-transition;
        @include r.sp {
            width: 2px;
            height: 2px;
            margin-right: 10px;
            transform: scale(1);
        }
    }
    @include r.hover {
        .c-arrow {
            transform: translateX(20px);
            &--style-01 {
                transform: translateX(10px);
            }
        }
        &::before {
            transform: scale(1);
        }
    }
    .c-arrow {
        margin-left: 30px;
        width: 66px;
        transition: v.$anime-transition;
        @include r.sp {
            margin-left: 15px;
            width: 30px;
        }
    }
}

//-------------------
.c-footer-btn {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 1.6rem;
    font-family: v.$font-zenKaku;
    text-transform: uppercase;
    transition: v.$anime-transition;
    position: relative;
    @include r.sp {
        font-size: 1.2rem;
    }
    &::before {
        content: '';
        width: 25px;
        height: 25px;
        transform: scale(0.16);
        display: block;
        background-color: v.$main-orange-color;
        border-radius: 50%;
        margin-right: 10px;
        transition: v.$anime-transition;
        @include r.sp {
            width: 2px;
            height: 2px;
            margin-right: 5px;
            transform: scale(1);
        }
    }
    @include r.hover {
        .c-arrow {
            transform: translateX(20px);
        }
        &::before {
            transform: scale(1);
        }
    }
    .c-arrow {
        margin-left: 20px;
        width: 30px;
        transition: v.$anime-transition;
        @include r.sp {
            margin-left: 15px;
            width: 21px;
        }
    }
    //-mini footer list btn
    &--style-01 {
        .c-arrow {
            opacity: 0;
            margin-left: 20px;
            width: 13px;
        }
        &::before {
            opacity: 0;
        }
        @include r.sp {
            .c-arrow {
                opacity: 1;
                margin-left: 20px;
                width: 13px;
            }
        }
        @include r.hover {
            margin-left: 10px;
            .c-arrow {
                opacity: 1;
                transform: translateX(10px);
            }
            &::before {
                opacity: 1;
                transform: scale(0.16);
            }
        }
    }
}

//-------------------------
.c-kakko-btn {
    //--
    margin-left: auto;
    margin-bottom: 130px;
    @include r.sp {
        margin-bottom: 75px;
    }
    //----
    width: 249px;
    height: 58px;
    padding-left: 40px;
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 500;
    transition: v.$anime-transition;
    position: relative;
    @include r.sp {
        width: 124px;
        height: 29px;
        padding-left: 10px;
        font-size: 0.9rem;
    }
    .c-arrow {
        width: 46px;
        margin-left: 15px;
        transition: v.$anime-transition;
        @include r.sp {
            width: 20px;
            margin-left: 10px;
        }
    }
    &::before,
    &::after {
        content: '';
        width: 9px;
        height: 100%;
        position: absolute;
    }
    &::before {
        border-left: solid 1px v.$text-brown-color;
        border-top: solid 1px v.$text-brown-color;
        border-bottom: solid 1px v.$text-brown-color;
        top: 0;
        left: 0;
    }
    &::after {
        border-right: solid 1px v.$text-brown-color;
        border-top: solid 1px v.$text-brown-color;
        border-bottom: solid 1px v.$text-brown-color;
        top: 0;
        right: 0;
    }
    @include r.hover {
        .c-arrow {
            transform: translateX(20px);
        }
    }
}
