@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
video
------------------------------------------------------------*/

//-----------------------
// .video {
//   width: 100%;
//   position: relative;
//   padding-top: 56.25%;
// }
// .video iframe {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
// }
