@use '../settings/mixin' as r;
@use '../settings/variable' as v;
@use '../settings/_path' as p;
@use '../../../node_modules/sanitize.css/sanitize.css';
@use '../../../node_modules/sanitize.css/forms.css';
* {
    padding: 0;
    margin: 0;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: inherit;
}

li {
    list-style-type: none;
}

th {
    font-weight: inherit;
}

address {
    font-style: inherit;
}

fieldset {
    min-width: 0;
    padding: 0;
    border: 0;
}

a {
    color: inherit;
    text-decoration: none;
    color: v.$text-brown-color;
}

img {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    width: 100%;
    max-width: 100%;
    height: auto;
    vertical-align: bottom;
}

button,
input,
select,
textarea {
    padding: 0;
    border: 0;
}

a,
button,
[role="button"],
[tabindex] {
    -webkit-tap-highlight-color: transparent;
}

html {
    font-size: 62.5%;
}

body {
    height: 100vh;
    font-family: 'Zen Kaku Gothic New', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: v.$text-brown-color;
}

// Safari用のハックは、Chromeに適用されないようにする
@supports (-webkit-touch-callout: none) {
    body {
        // Safari用のハック
        height: -webkit-fill-available;
    }
}

[v-cloak] {
    visibility: hidden;
}

.l-wrapper {
    position: relative;
    overflow: hidden;
}

.pc {
    @include r.mq(mdless) {
        display: none !important;
    }
}

.sp {
    @include r.mq(mdover) {
        display: none !important;
    }
}

//layout
main {
    padding-top: 92px;
    //product page
    margin-bottom: 200px;
    @include r.sp {
        padding-top: 60px;
        //product page
        margin-bottom: 50px;
    }
}

.l-container-fluid {
    max-width: 1920px;
    margin: 0 auto;
    @include r.desktop-pc {}
    @include r.sp {
        width: 100%;
    }
}

.l-container {
    max-width: 1080px;
    min-width: 1080px;
    margin: 0 auto;
    @include r.pc-1440 {
        padding-left: 2%;
        padding-right: 2%;
    }
    @include r.pc-1024 {
        min-width: 100%;
        padding-left: 2%;
        padding-right: 2%;
    }
    @include r.sp {
        width: 100%;
        padding-left: 8%;
        padding-right: 8%;
    }
}

.pc_none {
    display: none;
    @include r.sp {
        display: block;
    }
}

.sp_none {
    @include r.sp {
        display: none;
    }
}

//共通のスタイルを持つベースクラス
.u-font-lexend-exa {
    font-weight: 300;
    font-family: v.$font-lexend-exa;
    letter-spacing: -0.7px;
    @include r.sp {
        letter-spacing: -0.4px;
        font-size: 0.8rem;
    }
}

.u-font-ja {
    letter-spacing: 1.18px;
    font-weight: 500;
    line-height: 40px;
    @include r.sp {
        letter-spacing: 0.84px;
        line-height: 25px;
    }
}
