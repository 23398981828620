// $filePath: '/dirname/assets/';
$text-brown-color: #533025;
$main-orange-color: #f07700;
$nav-link-gray-color: #959595;
$label-pink-color: #B4817F;
$label-gold-color: #B9A97F;
$label-orange-color: #BE946A;
$label-blue-color: #A1B5BA;
$label-purple-color: #A49EAD;
$item-label-orange-color: #FEF8F2;
$border-orange-color: #FFE8CE;
$contact-border-gray-color: #707070;
$news-bg-gray-color: #e8eded;
$news-border-gray-color: #d5d3d3;
$font-zenKaku: 'Zen Kaku Gothic New',
sans-serif;
$font-lexend-exa: 'Lexend Exa',
sans-serif;
$anime-transition: all 0.6s;
