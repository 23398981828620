@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
title
------------------------------------------------------------*/

//--日本語＋オレンジの英語
.c-title-01 {
    margin-bottom: 10px;
    font-size: 3rem;
    font-weight: 500;
    line-height: 50px;
    @include r.sp {
        margin-bottom: 5px;
        font-size: 1.8rem;
        line-height: 27.5px;
    }
    &__sub_heading {
        margin-top: 0;
        display: block;
        color: v.$main-orange-color;
        font-size: 1.4rem;
        letter-spacing: -0.7px;
        font-weight: 300;
        font-family: v.$font-lexend-exa;
        @include r.sp {
            font-size: 0.7rem;
        }
    }
    //--insta
    &--style-01 {
        font-family: v.$font-lexend-exa;
        font-weight: 400;
        letter-spacing: -1.6px;
    } //--contact--
    &--style-02 {
        font-size: 2.6rem;
        margin-bottom: 30px;
        text-align: center;
        @include r.sp {
            margin-bottom: 15px;
            font-size: 1.8rem;
        }
    }
}

//--point-----------
.p-c-tittle-02 {
    margin-bottom: 80px;
    @include r.sp {
        margin-bottom: 50px;
    }
    //recommend
    &--style-01 {
        margin-bottom: 50px;
        @include r.sp {
            margin-bottom: 50px;
        }
    }
    //recommend
    &--style-02 {
        margin-bottom: 70px;
        @include r.sp {
            margin-bottom: 20px;
        }
    }
    //pudding
    &--style-03 {
        margin-bottom: 60px;
        @include r.sp {
            margin-bottom: 30px;
        }
    }
}

.c-title-02 {
    font-size: 3rem;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: 2.1px;
    text-align: center;
    @include r.sp {
        font-size: 1.5rem;
        line-height: 25px;
        letter-spacing: 1.05px;
    }
    &__sub_heading {
        margin-bottom: 20px;
        display: block;
        color: v.$main-orange-color;
        font-size: 1.7rem;
        letter-spacing: -0.85px;
        line-height: 6px;
        font-weight: 500;
        font-family: v.$font-lexend-exa;
        @include r.sp {
            margin-bottom: 10px;
            font-size: 0.9rem;
            letter-spacing: -0.45px;
            line-height: 3px;
        }
    }
    &::after {
        content: '';
        width: 50px;
        height: 1px;
        display: block;
        margin: 30px auto 0;
        background-color: v.$main-orange-color;
        @include r.sp {
            width: 25px;
            margin: 15px auto 0;
        }
    }
    &__txt {
        margin-top: 50px;
        font-size: 1.8rem;
        letter-spacing: 1.26px;
        line-height: 40px;
        font-weight: 500;
        text-align: center;
        @include r.sp {
            margin-top: 25px;
            font-size: 1.2rem;
            letter-spacing: 0.84px;
            line-height: 25px;
        }
    }
}

//--
.c-title-03 {
    font-size: 3rem;
    font-weight: 700;
    letter-spacing: 2.1px;
    line-height: 44px;
    @include r.sp {
        font-size: calc(3.2rem / 2);
        letter-spacing: calc(2.24px / 2);
        line-height: calc(46px / 2);
    }
}

//--with icon
.c-title-04 {
    font-size: 3rem;
    display: flex;
    align-items: center;
    font-weight: 700;
    letter-spacing: 2.1px;
    line-height: 44px;
    &__icon {
        display: inline-block;
        // vertical-align: middle;
        margin-right: 20px;
        @include r.sp {
            margin-right: 13px;
        }
        &--1 {
            width: 27px;
            @include r.sp {
                width: 13px;
            }
        }
        &--2 {
            width: 38px;
            @include r.sp {
                width: 19px;
            }
        }
        &--3 {
            width: 28px;
            @include r.sp {
                width: 14px;
            }
        }
        &--4 {
            width: 26px;
            @include r.sp {
                width: 13px;
            }
        }
        &--5 {
            width: 33px;
            @include r.sp {
                width: 15px;
            }
        }
        &--6 {
            width: 29px;
            @include r.sp {
                width: 15px;
            }
        }
        &--7 {
            width: 39px;
            @include r.sp {
                width: 20px;
            }
        }
        &--8 {
            width: 40px;
            @include r.sp {
                width: 20px;
            }
        }
        &--9 {
            width: 45px;
            @include r.sp {
                width: 20px;
            }
        }
    }
    @include r.sp {
        font-size: 1.6rem;
        letter-spacing: 1.12px;
        line-height: 23px;
    }
}

//--商品紹介--
.p-product-list-03 {
    .c-title-03 {
        margin-bottom: 50px;
        @include r.sp {
            margin-bottom: 25px;
        }
    }
}

//おやつ菓子
.p-product-list-03 {
    .c-title-04 {
        margin-bottom: 50px;
        @include r.sp {
            margin-bottom: 25px;
        }
    }
}

//店舗・大口のお客様へ
.p-cp-gift-flow {
    .c-title-04 {
        margin-bottom: 100px;
        @include r.sp {
            margin-bottom: 45px;
        }
    }
}
